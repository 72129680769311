/**
 * Base reset for all used input types. Covers background colors, text, outlines.
 * Some of the resets are also covered by the normalizers.
 * In here we overwrite them as the last, intended style.
 * Avoid adding overwrites that dont affect all inputs.
 */

input[type='text'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime-local'],
input[type='email'],
input[type='submit'],
input[type='file'],
input[type='url'],
input[type='tel'],
textarea,
.input-select {
  height: 36px;
  @extend .bg-grey-700;
  // background-color: transparent !important;
  @extend .radius-8;
  @extend .text-base;
  @extend .color-grey-100;
  @extend .p-2;
  border: none;
  @extend .transition-1;
  @extend .outline-secondary;
  outline: none;
  &:focus,
  &:active {
    @extend .outline-primary;
    outline: none;
  }
  &:disabled {
    @extend .color-grey-400;
  }
}

/**
  *  Textarea specific overwrites
  */
textarea {
  overflow: hidden;
  resize: none;
  height: '30px';
}

/**
  *  Input base. Handles uniform consistent heights. Acts as a flex container and it should not be added
  * directly on the input element.
  *
  * 1. The main container, for the label, input and helping text ( in main).
  * 1.2 Also sets the space between the above mentioned elements. Avoid adding margins to override this.
  *
  * 2. The label. Covers only the general text styles. Positioning is specific per input type.
  *
  * 3. The main input container. Wraps the actual input, plus additional overplayed icons
  *
  * 4. Helping text. Additional information on the input purpose. Only information, not for errors.
  *
  * 5. Icon version of the entire input component. Sets the icon positions.
  */

/* 1 */
.input-base {
  @extend .flex;
  @extend .flex-col;
  /* 1.2 */
  // @extend .gap-1;
  /* 2 */
  label {
    @extend .text-base;
    @extend .color-white;
  }
  /* 3 */
  .input-container {
    display: flex;
    @extend .w-full;
    @extend .relative;
    input,
    textarea,
    .input-select {
      @extend .w-full;
    }
    @extend .items-center;
  }
  /* 4 */
  .helping-text {
    @extend .text-base;
    @extend .color-grey-400;
    @extend .mt-1;
  }
  /* 5 */
  &.with-icon-left,
  &.with-icon-right {
    input,
    textarea,
    .input-select {
      @extend .w-full;
    }
    .icon-left {
      @extend .transition-1;
      @extend .input-icon;
      left: 8px;
    }
    .input-container {
      input,
      textarea,
      .input-select {
        @extend .w-full;
      }
      .icon-left {
        @extend .input-icon;
        left: 8px;
      }
      .icon-right {
        @extend .input-icon;
        right: 8px;
      }
    }
  }

  &.with-icon-left {
    input,
    textarea,
    .input-select {
      @extend .pl-8;
    }
  }

  &.with-icon-right {
    input,
    textarea,
    .input-select {
      @extend .pr-8;
    }
  }
}
/**
  *  Input icon base. Over-layered to maximise input area. Also allows for better click events.
  */
.input-icon {
  @extend .absolute;
  @extend .color-grey-100;
}

.invalid-input {
  box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.8) !important;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  color: white !important;
  -webkit-text-fill-color: white !important;
}

input[type='file']::-webkit-file-upload-button {
  display: none;
}

.successful input {
  color: var(--green);
}

.failed input {
  color: var(--red);
}

@import 'inputs/autocomplete';
@import 'inputs/input-select';