.newton-page-header {
  @extend .mb-8;
  @extend .flex;
  @extend .gap-4;
  @extend .flex-row;
  @extend .align-center;
}

.newton-landing-card {
  @extend .p-2;
  @extend .radius-8;
  @extend .bg-grey-900;
  @extend .color-white;
  flex-basis: 250px;
  flex-shrink: 0;
  min-height: 100px;
}
