/**
 * Flex classes.
 */
.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

/**
 * Flex directions
 */

$directions: (
  col: column,
  row: row
);
@include mapCSSRule('flex', 'flex-direction', $directions);

/**
 * Flex alignments
 */

$alignments: (
  start: flex-start,
  center: center,
  end: flex-end,
  between: space-between,
  around: space-around
);

$growth: (
  1: 1,
  2: 2
);

@include mapCSSRule('items', 'align-items', $alignments);
@include mapCSSRule('justify', 'justify-content', $alignments);
@include mapCSSRule('grow', 'flex-grow', $growth);

$flexGaps: (
  1: var(--space-1),
  2: var(--space-2),
  3: var(--space-3),
  4: var(--space-4),
  5: var(--space-5),
  6: var(--space-6),
  8: var(--space-8),
  16: var(--space-16),
  32: var(--space-32)
);
@include mapCSSRule('gap', 'gap', $flexGaps);

.flex-wrap {
  flex-wrap: wrap;
}

/**
 * Flex commons usage classes
 */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
