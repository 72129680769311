:root {
  // dev style, not for production
  --text-dev: #ef6a5c;
  // grey color schema
  --grey-900: #161419;
  --grey-800: #23242a;
  --grey-750: #292a30;
  --grey-700: #3f3f45;
  --grey-600: #52525a;
  --grey-500: #717179;
  --grey-400: #a1a1a9;
  --grey-300: #d4d4d8;
  --grey-200: #e4e4e7;
  --grey-100: #f4f4f5;
  --grey-50: #fafafa;
  --white: #ffffff;

  // primary accent colors
  --blue: #2b77ff;
  --yellow: #fabb2b;
  --light-yellow: #ffd981;
  --red: #ea4451;
  --green: #00c557;

  // spacers, in multiples of 2, ( 3 & 5 are exceptions )
  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 0.75rem;

  --space-4: 1rem;
  --space-5: 1.25rem;
  --space-6: 1.5rem;
  --space-8: 2rem;
  --space-16: 4rem;
  --space-24: 6rem;
  --space-32: 8rem;
  --space-64: 16rem;
  --space-0: 2.5rem;
}

/*-------- SETUP ------*/
@import '_abstract/normalizer';
@import '_abstract/general';
@import '_abstract/mixins';

/*---------FOUNDATIONS-------------*/
@import 'foundations/typography';
@import 'foundations/design-system';
@import 'foundations/animations';

/*---------COMPONENTS-------------*/
@import 'components/containers';
@import 'components/errors';
@import 'components/toast';

/*---------ATOMS-------------*/
@import 'components/atoms/button';
@import 'components/atoms/inputs';
@import 'components/atoms/label';
@import 'components/atoms/links';
@import 'components/atoms/tags';
@import 'components/atoms/cursor';
@import 'components/atoms/buttonTabs';
@import 'components/atoms/dropdown';

/*---------MOLECULES-------------*/
@import 'components/molecules/card';
@import 'components/molecules/loadingbar';
@import 'components/molecules/modal';

/*---------ORGANISMS-------------*/
@import 'components/organisms/configuration';
@import 'components/organisms/data-table';
@import 'components/organisms/table';
@import 'components/organisms/sidebar';
@import 'components/organisms//mobile-navbar.scss';

/*---------BUSINESS-------------*/
@import 'business/newton.scss';
@import 'business/auth.scss';
@import 'business/tasks.scss';
@import 'business/media.scss';
