/**
 *  Documentation to be added. WIP
 */

.table {
  &-link {
    @extend button;
    font-size: 1rem;
  }
}

.table-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  transition: height 0.5s;
  max-height: 1000px;
}

.table-cell {
  height: 35px;
  // @extend .w-full;
  // @extend .flex;
  // @extend .items-center;
  @extend .gap-2;
  // @extend .border-grey-700;
  border-bottom: 1px solid var(--grey-700);
  text-align: left;
  @extend .color-grey-300;
  @extend .text-md;
}

.table-cell-content {
  @extend .table-cell;
  @extend .pl-4;
  .profit {
    color: green;
  }
  .loss {
    color: red;
  }
}

.table-cell-content-tooltip-text {
  @extend .color-white;
  @extend .radius-6;
  @extend .text-center;
  @extend .bg-grey-900;
  @extend .p-3;
  @extend .absolute;

  visibility: hidden;
}

.table-cell-content-tooltip:hover + .table-cell-content-tooltip-text {
  visibility: visible;
}

.table-cell-action {
  @extend .table-cell;
}

.table-cell-header {
  @extend .table-cell;
  @extend .px-4;
  // @extend .color !optional;
  @extend .bg-grey-600;
  @extend .color-white;
  // @extend .text-md-bold;
  th {
    @extend .color-white;
    @extend .px-4;
  }
}

.table-container {
  @extend .bg-grey-800;
  @extend .border-grey-700;
  @extend .radius-6;
  @extend .w-full;
  margin-bottom: 20px;
  // border-collapse: collapse;
  border-spacing: 0;
  overflow-x: scroll;

  .table-row {
    @extend .w-full;
    @extend .flex;
  }
  tr:nth-child(even) {
    background-color: var(--grey-750);
  }

  $blue: #2b77ff;
  tr.selected {
    background-color: darken($blue, 15%);

    .button-secondary {
      @extend .button-primary;
    }
  }
}

.table-cell-action-container {
  @extend .flex;
  @extend .justify-end;
  @extend .pr-1;
}

.pill-container {
  overflow: hidden;
  @extend .flex;
  @extend .items-center;
  @extend .table-cell-content-tooltip;
}

.table-title {
  @extend .color-grey-500;
  @extend .mb-4;
  @extend .text-base-bold;
}
