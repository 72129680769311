/**
 * Main containers for ease of usage
 * Main container : one per page
*/

.main-section {
  @extend .w-full;
  @extend .h-auto;
  @extend .bg-grey-800;
  @extend .radius-16;
  @extend .border-grey-700;
  @extend .px-8;
  @extend .py-4;
}

/**
 * Section container,used to mark and contain a group of elements. Should not be chained visually.
*/
.section-container {
  @extend .w-full;
  @extend .h-auto;
  @extend .bg-grey-800;
  @extend .radius-16;
  @extend .border-grey-700;
  @extend .p-4;
  flex-wrap: wrap;
}

.section-container-title {
  @extend .mb-2;
  color: var(--grey-300)
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--grey-600);
  overflow: hidden;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background: var(--grey-800);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.actions-container {
  @extend .flex-between;
  @extend .flex-row;
}

.center-container {
  @extend .flex;
  @extend .flex-col;
  @extend .justify-center;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  max-height: auto;
}

.flex-between-container {
  @extend .flex-between;
}

.flex-section-container {
  @extend .section-container;
  @extend .mb-4;
  @extend .flex;
  @extend .flex-row;
}

.highlight-cards {
  @extend .flex;
  @extend .flex-row;
  @extend .gap-3;
  @extend .mb-8;
  @extend .w-full;
  @extend .p-1;
  @extend .pb-2;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.half-end {
  width: 50%;
  @extend .flex;
  @extend .flex-row;
  @extend .flex-end;
}

.container-with-border {
  @extend .p-4;
  @extend .radius-16;
  @extend .border-grey-700;
}
