/*
 * Document
 */

/**
 * 1.  Normalizes widths and height behaviour for elements
 */
* {
  box-sizing: border-box; /* 1 */
}

/**
 * 1.  Class for when the body needs to locked from scrolling. Eg. modal open
 * 2.  Styles for the react app main container
 */
body {
  background-color: var(--grey-900);
  color: var(--white);
  overflow: hidden;
  height: 100vh;
  width: 100vw;

  #root {
    /* 1 */
    // width: 100vw;
    position: relative;
    display: flex;
  }
}

/**
 * 1.  Class for when the body needs to locked from scrolling. Eg. modal open
 * 2.  Styles for the react app main container
 */
main {
  @extend .h-auto;
  @extend .py-4;
  @extend .pl-0;
  @extend .pr-4;
  @extend .w-full;
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  overscroll-behavior: contain;

  .inner-content {
    width: 100%;
    min-width: 100%;
    min-height: calc(100vh - 2 * var(--space-4));
    @extend .h-auto;
    @extend .bg-grey-800;
    @extend .radius-16;
    @extend .border-grey-700;
    @extend .px-8;
    @extend .py-4;
  }

  &.main-mobile {
    padding: 12px 12px 64px 12px;
  }
}

/**
 * 1. Reset for links decoration
 */
a {
  text-decoration: none; /* 1 */
}
