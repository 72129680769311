.mobile-navbar {
  &.MuiPaper-root {
    @extend .flex-row;
    @extend .py-2;
    @extend .px-3;
    @extend .bg-grey-900;
    z-index: 1250;
    bottom: 0;
  }
}

.drawer-button {
  @extend .p-1;
  @extend .bg-grey-900;

  &.active {
    @extend .bg-grey-600;
    border-radius: 10%;
  }
}

.mobile-searchbar-dropdown {
  position: relative;
  width: 100%;
  background-color: var(--grey-900);
  border: none;
  border-radius: 0px;
  &.active {
    max-height: 250px;
  }
}

.mobile-searchbar-container {
  width: 100%;
  position: absolute;
  bottom: 36px;
  left: 0;
}
