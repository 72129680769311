/**
 * Border radius
 */

$radiuses: (
  4: 4px,
  6: 6px,
  8: 8px,
  10: 10px,
  12: 12px,
  16: 16px,
  20: 20px,
  24: 24px
);
@include mapCSSRule('radius', 'border-radius', $radiuses);

/**
 * Borders. Only used border colours, can be expanded
 */

$borderColors: (
  grey-500: 1px solid var(--grey-500),
  grey-900: 1px solid var(--grey-900),
  grey-800: 1px solid var(--grey-800),
  grey-700: 1px solid var(--grey-700),
  grey-600: 1px solid var(--grey-600),
  blue: 1px solid var(--blue),
  red: 1px solid var(--red),
  green: 1px solid var(--green),
  // testing class
  test: 1px solid red,
  text-dev: var(--text-dev)
);

@include mapCSSRule('border', 'border', $borderColors);

/**
 * Outlines. It will also apply hover effects. It uses box shadows
 * to prevent 90deg border radius bug on safari
*/
.outline-primary {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
  &:active,
  &:focus,
  &:visited,
  &:hover {
    box-shadow: 0 0 0 2px rgba(50, 116, 200, 0.8);
  }
}
.outline-secondary {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
  &:hover {
    box-shadow: 0 0 0 2px rgba(256, 256, 255, 0.15);
  }
}

.shadow-page {
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.7),
    0px 20px 30px rgba(0, 0, 0, 0.3),
    0px 10px 50px rgba(0, 0, 0, 0.2);
}
