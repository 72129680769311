/**
 * Main card-container
*/
.card-container {
  @extend .flex-center;
  @extend .flex-col;
  @extend .p-3;
  @extend .radius-16;
  display: flex;
  background-color: var(--grey-700);
}

.highlight-card {
  @extend .card-container;
  @extend .flex-start;
  flex-basis: 225px;
  flex-shrink: 0.01;
  height: 110px;

  &.stretch {
    flex-grow: 1;
  }

  .title {
    @extend .flex-end;
    @extend .flex;
    @extend .gap-3;
    align-self: flex-start;

    .trend {
      @extend .flex;
      @extend .flex-col;
    }

    .max {
      font-weight: 600;
      font-size: 3rem;
      white-space: nowrap;
    }

    .min {
      font-weight: 600;
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .description {
    @extend .mt-3;
    align-self: flex-start;
    white-space: nowrap;
  }

  &.profit > div > span {
    color: var(--green);
  }

  &.loss > div > span {
    color: var(--red);
  }
}

a.highlight-card {
  color: inherit;
}
