/**
 *  Transitions. Gradually increases in time
 */
.transition-1 {
  transition: all 0.1s ease-out;
}
.transition-2 {
  transition: all 0.2s ease-out;
}
.transition-3 {
  transition: all 0.3s ease-out;
}

.fade-in {
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
}

.fade-in-fast {
  opacity: 0;
  animation: fadeIn linear 0.4s;
  -webkit-animation: fadeIn linear 0.4s;
  -moz-animation: fadeIn linear 0.4s;
  -o-animation: fadeIn linear 0.4s;
  -ms-animation: fadeIn linear 0.4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  opacity: 0;
  animation: fadeOut linear 1s;
  -webkit-animation: fadeOut linear 1s;
  -moz-animation: fadeOut linear 1s;
  -o-animation: fadeOut linear 1s;
  -ms-animation: fadeOut linear 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
