/**
 * Tags
*/

.tag {
  @extend .flex;
  @extend .flex-center;
  @extend .gap-1;
  @extend .radius-8;
  @extend .border-grey-700;
  @extend .px-2;
  @extend .py-1;
  @extend .text-md-bold;
  @extend .color-grey-200;
  @extend .bg-grey-800;
  cursor: pointer;
  @extend .transition-2;
  &:hover {
    @extend .bg-grey-700;
  }
}
