/**
Remove default button style
*/
button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

button:disabled:not(.pagination-button):not(.toggle-button) {
  @extend .bg-grey-700;
  color: var(--grey-500);
  border: none;
}

button:disabled:hover {
  box-shadow: none;
}

.pagination-button:disabled {
  cursor: default;
  p {
    cursor: default;
    color: var(--grey-500);
  }
}

/**
 * Button base. Normalizes height and border radius.
 * Padding is fixed and should not be overwritten
 * Same goes for text size, content aligning.
*/

.button-base {
  height: 36px;
  cursor: pointer;
  @extend .w-auto;
  @extend .text-base-bold;
  @extend .py-2;
  @extend .px-4;
  @extend .flex-center;
  @extend .radius-6;
  @extend .gap-1;
  @extend .transition-1;

  &.xs {
    height: 28px;
    @extend .text-md-bold;
  }
}
/**
 * Button primary. Should be used with intent, only one max 2 per active focused window.
 * Should stand for the main action, for the rest use secondary buttons
  * Primary styles
*/
.button-primary {
  @extend .button-base;
  @extend .bg-blue;
  @extend .color-white;
  @extend .border-blue;
  @extend .outline-primary;
}
/**
 * Secondary primary. Used for normal actions, that dont need to stand out.
 * Can be used without a primary button in view.
 * Secondary styles
*/
.button-secondary {
  @extend .button-base;
  @extend .bg-grey-800;
  @extend .color-white;
  @extend .border-grey-600;
  @extend .outline-secondary;
}
/**
 * Tertiary primary. Used for normal, lesser actions, that dont need to stand out.
 * Can be used without a primary button in view.
 * Tertiary styles
*/
.button-tertiary {
  @extend .button-base;
  background-color: transparent;
  border: none;
  @extend .color-white;
  @extend .outline-secondary;
}

/**
 * Success button. Has the same effect as a primary button
 * Should convey a single action, with a positive, successful output/outcome.
 * Success styles
*/
.button-success {
  @extend .button-base;
  @extend .bg-grey-800;
  @extend .border-green;
  @extend .color-green;
  @extend .outline-secondary;
}
/**
 * Danger icon. Has the same effect as a primary button
 * Should convey a single action, with a destructive, non reversible output/outcome.
 * Danger styles
*/
.button-danger {
  @extend .button-base;
  @extend .bg-grey-800;
  @extend .border-red;
  @extend .color-red;
  @extend .outline-secondary;
}

/**
 * Link icon button.
 * Link styles
*/
.button-table {
  height: 36px;
  justify-content: flex-start;
  cursor: default;
  @extend .truncate;
  @extend .w-auto;
  @extend .text-base-bold;
  @extend .flex;
  @extend .align-center;
  @extend .radius-6;
  @extend .transition-1;
}

.button-table-action {
  height: 26px;
  cursor: pointer;
}

/**
 * Same as button base, but for only icons and with a uniform padding all around.
 * Support sizes, mainly for table cells and inline button groups
*/
.icon-button-base {
  width: 36px;
  height: 36px;
  padding: 0;
  &.xs {
    width: 28px;
    height: 28px;
  }
  &.lg {
    width: 40px;
    height: 40px;
  }
}
/**
 * Primary icon button. Used to symbolise a shorter primary button, such as the settings of a section
 * Primary styles
*/
.icon-button-primary {
  @extend .button-primary;
  @extend .icon-button-base;
}
/**
 * Secondary icon button. Used to symbolise a shorter secondary button, with inline scope
 * Should not serve as a icon container displayer, but as a button.
 * Should not be used a close icon for modals, containers, overlays.
 * Secondary styles
*/
.icon-button-secondary {
  @extend .button-secondary;
  @extend .icon-button-base;
  flex-shrink: 0;
}
/**
 * Tertiary icon button.
 * Should not serve as a icon container displayer, but as a button.
 * Should not be used a close icon for modals, containers, overlays.
 * Tertiary styles
*/
.icon-button-tertiary {
  @extend .button-tertiary;
  @extend .icon-button-base;
}
/**
 * Success icon button.
 * Success styles
*/
.icon-button-success {
  @extend .button-success;
  @extend .icon-button-base;
}
/**
 * Danger icon button.
 * Danger styles
*/
.icon-button-danger {
  @extend .button-danger;
  @extend .icon-button-base;
}
/**
 * Link icon button.
 * Link styles
*/
.button-link {
  @extend .clickable;
  @extend .button-table;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
  span {
    cursor: pointer;
  }
}

.disabled {
  color: #3f3f45;
  &:hover {
    box-shadow: none;
    cursor: default;
  }
}
