.input-select-container {
  @extend .input-base;

  .input-container {
    min-width: 200px;

    .input-select {
      @extend .relative;
      @extend .truncate;
      text-align: left;
      padding-right: 30px;

      &.with-icon {
        padding-left: 36px;
      }

      &:disabled {
        color: var(--grey-500);
      }
    }

    .icon-left,
    .icon-right {
      @extend .transition-1;
      @extend .input-icon;
      @extend .absolute;
      pointer-events: none;
      cursor: pointer;
      z-index: 1;

      &.disabled {
        @extend .input-icon;
        right: 8px;
        color: var(--grey-500);
      }
    }

    .icon-left {
      left: 8px;
    }

    .icon-right {
      right: 8px;
    }
  }
}

.base-Select-popup {
  @extend .z-1000;

  .base-Select-listbox {
    @extend .w-full;
    @extend .t-full;
    @extend .l-0;
    @extend .mt-2;
    width: 300px;
    padding: 0;
    list-style: none;
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    outline: none;

    @extend .radius-8;
    @extend .bg-grey-800;
    @extend .border-grey-700;

    .option {
      @extend .w-full;
      @extend .p-4;
      @extend .color-grey-300;
      @extend .transition-1;
      cursor: pointer;

      &:hover,
      &.base-Option-highlighted,
      &:focus-visible,
      &:focus {
        @extend .bg-grey-700;
        @extend .color-grey-500;
      }
    }
  }
}
