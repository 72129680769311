.data-table {
  &.MuiDataGrid-root {
    border: none;
    @extend .mb-4;
  }

  .data-table-main {
    outline: 1px solid var(--grey-700);
    @extend .radius-6;
  }

  .MuiDataGrid-columnHeaders.data-table-column-headers {
    @extend .border-grey-700;

    .data-table-cell {
      background-color: var(--grey-600) !important;
      @extend .color-white;
    }

    .data-table-column-separator {
      display: none !important;
    }
  }

  .data-table-row {
    background-color: var(--grey-800) !important;

    &:nth-child(even) {
      background-color: var(--grey-750) !important;
    }

    &.Mui-selected {
      $blue: #2b77ff;
      background-color: darken($blue, 15%) !important;

      .button-secondary {
        @extend .button-primary;
      }
    }

    &.last-row {
      background-color: var(--grey-700) !important;
    }
  }

  .data-table-row .data-table-cell,
  .data-table-column-headers .data-table-cell {
    border-left: none;
    border-right: none;
    @extend .border-grey-700;
    @extend .color-grey-300;
    @extend .text-md;

    &.bold {
      font-weight: bold;
    }

    &:hover {
      background: inherit;
    }

    &:focus-within {
      outline: none;
    }

    &.profit {
      color: var(--green);
    }

    &.warning {
      color: var(--yellow);
    }

    &.loss {
      color: var(--red);
    }

    &.info {
      color: var(--blue)
    }

    a {
      @extend .truncate;
      @extend .inline;
      @extend .text-light;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    .data-table-cell-content {
      @extend .truncate;
      @extend .w-full;

      &.actions {
        display: inline-flex;
        align-items: inherit;
        justify-content: inherit;
      }
    }

    .data-table-cell-icon {
      @extend .mr-1;
      @extend .flex;
      @extend .align-center;
      @extend .h-full;
    }
  }

  .data-table-row .data-table-cell.narrow,
  .data-table-column-headers .data-table-cell.narrow {
    @extend .text-xs;
    padding: 0 6px;
  }

  .MuiDataGrid-sortIcon {
    color: var(--white);
  }

  .data-table-footer {
    border: none;
    @extend .justify-start;
  }

  .data-table-overlay {
    display: none;
  }

  .MuiDataGrid-cell {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

.data-table-title {
  @extend .color-grey-500;
  @extend .text-base-bold;
  @extend .mb-2;
}