/**
 *  Sidebar containing the main navigation
 * Supports minimised mode
 */
.nav-sidebar {
  width: auto;
  height: 100%;
  @extend .flex;
  @extend .flex-col;
  @extend .p-8;
  @extend .bg-grey-900;
  &.minimised {
    .user-profile img,
    .menu-item {
      width: 40px;
    }
    .menu-item span {
      opacity: 0;
      max-width: 0;
      @extend .ml-0;
    }
    .user-profile .text {
      display: none;
    }
  }
  &.mobile {
    @extend .p-4;
  }
}

/**
 *  User profile card inside the sidebar
 */
.user-profile {
  width: 100%;
  text-align: center;
  color: var(--grey-200);
  img {
    width: 100px;
    @extend .transition-2;
  }
  .text {
    overflow: hidden;
    opacity: 1;
    max-width: 100%;
    transition:
      opacity 0.2s ease-out,
      max-width 0.2s ease-out 0.3s;
  }
}

/**
 *  Sidebar menu item
 * Support active/selected mode
 */
.menu-item {
  width: 200px;
  height: 40px;
  align-items: center;
  cursor: pointer;
  @extend .flex;
  @extend .transition-2;
  @extend .color-grey-400;
  @extend .p-2;
  @extend .radius-6;

  span {
    transition:
      opacity 0.1s ease-out,
      max-width 0.1s ease-out 0.1s;
    @extend .text-base;
    @extend .ml-4;
    overflow: hidden;
    opacity: 1;
    max-width: 100%;
  }
  &:hover {
    @extend .bg-grey-700;
    @extend .color-grey-200;
  }
  &.active {
    @extend .bg-grey-500;
    @extend .color-grey-100;
  }
}

.collapse-button {
  @extend .w-auto;
  @extend .menu-item;
  @extend .mt-auto;
  @extend .color-grey-500;
}

.buttons-container {
  @extend .flex;
  @extend .flex-col;
  @extend .mt-auto;
}

.sidebar-menu {
  min-height: 175px;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-drawer {
  min-height: 200px;

  & .MuiPaper-root {
    position: relative;
    border: none;
    z-index: 0;
  }
}

.mobile-sidebar-drawer {
  & .MuiPaper-root {
    padding-bottom: 50px;
  }
}
