/* Document
   ========================================================================== */

/**
 * 1. Initiates a class markup with a . followed by a classname, usually a
 combination of letters. Notice the \. which
 * 2. Concatenates the rest of the classes from the output of the function.
 */
@mixin mapCSSRule($className, $cssAttribute, $aValues) {
  #{'\.' + $className} {
    /* 1 */
    @include singleLevelMapping($cssAttribute, $aValues); /* 2 */
  }
}
/**
 * 1. Initiates a class markup with a . followed by a classname, usually a
  combination of letters
 * 1.1. Same as above, but with a directional letters level, meaning it will
 cover left, right, top, bottom, x and y axis.
 */
@mixin mapCSSRuleDirectional($className, $cssAttribute, $aValues) {
  #{'\.' + $className} {
    @include directionalCSSMapping($cssAttribute, $aValues); /* 1 */
  }
}

/**
 * 1. takes an array of key:value pairs and outputs css rules,
  contained inside a css rule prefixed with. It needs to be contained inside a master class.
 */
@mixin singleLevelMapping($attribute, $map, $important: false) {
  @each $key, $value in $map {
    &-#{$key} {
      #{$attribute}: $value#{if($important == true, '\!important', '')};
    }
  }
}

@mixin directionalCSSMapping($cssAttribute, $aValues) {
  &#{''} {
    @include singleLevelMapping($cssAttribute, $aValues);
  }
  &#{'l'} {
    @include singleLevelMapping($cssAttribute + '-left', $aValues);
  }
  &#{'r'} {
    @include singleLevelMapping($cssAttribute + '-right', $aValues);
  }
  &#{'t'} {
    @include singleLevelMapping($cssAttribute + '-top', $aValues);
  }
  &#{'b'} {
    @include singleLevelMapping($cssAttribute + '-bottom', $aValues);
  }
  &#{'x'} {
    @each $key, $value in $aValues {
      &-#{$key} {
        #{$cssAttribute}-left: $value;
        #{$cssAttribute}-right: $value;
      }
    }
  }
  &#{'y'} {
    @each $key, $value in $aValues {
      &-#{$key} {
        #{$cssAttribute}-top: $value;
        #{$cssAttribute}-bottom: $value;
      }
    }
  }
}

/**
 * 1. Strips unit from any css value declaration
 */
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

/**
 * Fluid typography mixin. Not used yet
 */
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
