.button-tabs {
  height: 100%;
  border: 1px solid var(--grey-600);
  @extend .radius-8;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  &-item {
    height: 100%;
    display: flex;
    flex: 1;
    background-color: var(--grey-700);
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: var(--grey-500);
    }

    &.selected {
      background-color: var(--grey-900);
    }

    &:not(:first-child) {
      border-left: 1px solid var(--grey-600);
    }
  }
}
