.register-agreements-container {
  @extend .section-container;
  @extend .flex;
  @extend .flex-col;
  @extend .gap-2;
  @extend .p-5;
  margin-bottom: 2vh;
}

.two-factor-authentication-input {
  font-size: 1.5rem;
  height: 2.5rem;
  text-align: center;
  min-width: auto;
  width: 2.5rem;

  input {
    font-size: 1.5rem;
    text-align: center;
  }
}
