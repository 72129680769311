/**
 *  Declaration of font families
 */
@font-face {
  font-family: 'SF Pro Display Semibold';
  src: url('../../fonts/SFProDisplay-Semibold.ttf');
}

@font-face {
  font-family: 'SF Pro Text Bold';
  src: url('../../fonts/SFProText-Bold.ttf');
}

@font-face {
  font-family: 'SF Pro Text Regular';
  src: url('../../fonts/SFProText-Regular.ttf');
}

/**
 *  General font family declaration
 */
body,
* {
  font-family: 'SF Pro Text Regular', serif;
}

/**
 *  Reset on spacings on typography units
 */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

/**
 *  Dev test, not to be used. Fluid typography for when responsiveness is needed.
 */
$min_w: 320px;
$max_w: 1280px;

.text-test {
  @include fluid-type($min_w, $max_w, 14px, 18px);
}

/**
 *  Bigger header used for entire page titles
 */
.text-xl {
  font-size: 26px;
  font-family: 'SF Pro Display Semibold', serif;
  line-height: 36px;
  text-decoration: none;
}
/**
 *  Main header, used for section headers
 */
.text-lg {
  font-size: 18px;
  font-family: 'SF Pro Display Semibold', serif;
  line-height: 28px;
  text-decoration: none;
}
/**
 *  Small header, used for inline information
 */
.text-header {
  font-size: 13px;
  font-family: 'SF Pro Display Semibold', serif;
  line-height: 20px;
  text-decoration: none;
}

/**
 *  Base text, for most usages
 *  Bold variant
 */
.text-base-bold {
  font-size: 16px;
  font-family: 'SF Pro Text Bold', serif;
  // line-height: 24px;
  text-decoration: none;
}
.text-base {
  font-size: 16px;
  font-family: 'SF Pro Text Regular', serif;
  font-weight: normal;
  font-style: normal;
  // line-height: 24px;
  text-decoration: none;
}

/**
 *  Medium sized text, for when information comes in a more complex format
 *  Bold variant
 */
.text-md-bold {
  font-size: 14px;
  font-family: 'SF Pro Text Bold', serif;
  line-height: 20px;
  text-decoration: none;
}

.text-md {
  font-size: 14px;
  font-family: 'SF Pro Text Regular', serif;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
}
/**
 *  Extra small sized text, used for tags, labels, small annotations, etc
 */
.text-xs {
  font-size: 12px;
  font-family: 'SF Pro Text Regular', serif;
  font-weight: normal;
  font-style: normal;
  line-height: 16px;
  text-decoration: none;
}

.text-light {
  font-weight: 400;
}

.text-additional {
  @extend .color-grey-300;
}

.noWrap {
  white-space: nowrap;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
 *  Util class. Uppercase's the font
 */
.uppercase {
  text-transform: uppercase;
}

/**
 *  Text alignments
 */
$textAlignments: (
  start: start,
  left: left,
  center: center,
  right: right,
  end: end
);
@include mapCSSRule('text', 'text-align', $textAlignments);

/**
 *  Section header. Semi-hidden normal header with a cut from the bottom.
 * Effect achieved with a fixed height and overflow hidden.
 */
.section-header {
  height: 18.5px;
  overflow-y: hidden;
  @extend .pl-8;
  @extend .text-lg;
  @extend .color-grey-500;
}

/**
 *  Dev text font import
 */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
.text-dev {
  font-family: 'IBM Plex Mono', monospace;
}

/**
 *  Show elipsis (...) on text longer than container
 */
.show-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.yellow-text {
  color: #fabb2a;
  @extend .pr-1;
}
