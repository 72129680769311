/**
 * Displays
 */
.block {
  display: block;
}
.hidden {
  display: none;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}

/**
 * Positions
 */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

/**
 * Common classes for item positioning
 * 1. used to center elements outside of relative flow.
 * 2. quick top left overlay position

 */
.t-0 {
  top: 0;
}
.t-full {
  top: 100%;
}
.r-0 {
  right: 0;
}
.l-0 {
  left: 0;
}
.l-50 {
  left: 50%;
  transform: translateX(-50%);
}
.b-0 {
  bottom: 0;
}
/* 2 */
.absolute-0 {
  position: absolute;
  top: 0;
  left: 0;
}

/**
 * Z indexes
*/
$zIndexes: (
  0: 0,
  1: 1,
  10: 10,
  20: 20,
  50: 50,
  1000: 1000,
  2000: 2000,
  9999: 9999,
  max: 9999
);

@include mapCSSRule('z', 'z-index', $zIndexes);

/**
 * whitespace 
*/
$whitespaces: (
  pre: pre
);

@include mapCSSRule('ws', 'white-space', $whitespaces);
