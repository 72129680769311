.permission {
  & > .permission {
    padding-left: 30px;
  }
}

.view-management-button-wrapper {
  @extend .flex;
  @extend .justify-end;
  @extend .mt-4;
}
