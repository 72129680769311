autocomplete {
    border: 0;
    margin: 0;
    outline: none;
    position: relative;
    @extend .radius-8;
    @extend .input-base;
    @extend .w-full;
  
    .container-active {
      box-shadow: 0 0 0 2px rgba(50, 116, 200, 0.8);
      cursor: default;
    }
  
    .input-container {
      min-height: 36px;
      cursor: pointer;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      @extend .pr-6;
      @extend .radius-8;
      @extend .bg-grey-700;
      @extend .border-grey-700;
  
      input {
        padding: var(--space-2);
        outline: none;
        @extend .bg-grey-700;
        @extend .color-white;
        @extend .pr-8;
      }
  
      .icon-left {
        @extend .input-icon;
        left: 8px;
        top: 0.3rem;
      }
  
      .icon-right {
        @extend .input-icon;
        right: 8px;
        top: 0.3rem;
      }
  
      .icon-right-disabled {
        @extend .input-icon;
        right: 8px;
        color: var(--grey-500);
      }
  
      .icon-right:hover {
        cursor: pointer;
      }
  
      .icon-clear {
        @extend .input-icon;
        right: 28px;
      }
      .icon-clear:hover {
        color: red;
        cursor: pointer;
      }
  
      input:active {
        box-shadow: none;
        border: none;
      }
  
      input:active + .autocomplete-dropdown {
        display: block;
      }
    }
  }
  
  .autocomplete-label {
    @extend .label-base;
  }
  
  .autocomplete-dropdown {
    @extend .w-full;
    @extend .t-full;
    @extend .l-0;
    @extend .mt-2;
    width: 300px;
    padding: 0;
    list-style: none;
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 17000;
    position: absolute;
  
    @extend .radius-8;
    @extend .bg-grey-800;
    @extend .border-grey-700;
    @extend .z-1000;
    max-height: 0px;
    overflow-y: auto;
    opacity: 0;
    transition:
      max-height 0.001s linear,
      opacity 0.2s linear;
  
    &.active {
      max-height: 200px;
      opacity: 1;
      top: calc(100% + 4px);
    }
  }
  
  .autocomplete-option {
    cursor: pointer;
    @extend .w-full;
    @extend .p-4;
    @extend .color-grey-300;
    @extend .transition-1;
  
    &:hover,
    &.Mui-focused {
      @extend .bg-grey-700;
      @extend .color-grey-400;
    }
  
    &:disabled {
      @extend .color-grey-700;
    }
  }