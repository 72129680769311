.tasks-status-input {
  .input-select {
    @extend .flex;
    @extend .align-center;
    @extend .color-white;
    @extend .border-grey-600;
    @extend .bg-grey-800;
    @extend .outline-secondary;
    background-color: transparent;
    max-height: 28px;
  }

  .input-container {
    min-width: 135px;
  }
}

.in-progress button {
  color: var(--yellow) !important;
}

.done button {
  color: var(--green) !important;
}

.stuck button {
  color: var(--red) !important;
}
