/**
 * Main spacings units. Built for a up to 5 column grid layout.
 */
$marginsAndPaddings: (
  '0': 0,
  '1': var(--space-1),
  '2': var(--space-2),
  '3': var(--space-3),
  '4': var(--space-4),
  '5': var(--space-5),
  '6': var(--space-6),
  '8': var(--space-8),
  '16': var(--space-16),
  '24': var(--space-24),
  '32': var(--space-32),
  '64': var(--space-64),
  auto: auto
);

/**
 * Margins and paddings They should be kept outside of class components, unless part of a consistent uniform layout.
 * Should be used on the element intended.
 * Supports directions: top, bottom, left, right, x-axis and y-axis.
 * They are built on a multiple of 2 increments, with exceptions for 3 and 5 for finer values.
 * Uses multiples of *rem
 */

@include mapCSSRuleDirectional('m', 'margin', $marginsAndPaddings);
@include mapCSSRuleDirectional('p', 'padding', $marginsAndPaddings);

/**
 * Dividers. Used to separate sections, elements, layouts.
 * Should be used as the default instead of adding border left right top or bottom.
 */
.divider-vertical {
  width: 1px;
  @extend .h-auto;
  @extend .bg-grey-500;
}
.divider-horizontal {
  height: 1px;
  @extend .w-auto;
  @extend .bg-grey-500;
}
