/**
 * Dropdown and dropdown items
*/
.dropdown-item {
  @extend .flex;
  @extend .items-center;

  @extend .px-4;
  @extend .py-3;
  @extend .text-md;
  @extend .bg-grey-800;
  @extend .color-white;
  @extend .transition-1;
  &:hover {
    @extend .bg-grey-700;
    cursor: pointer;
  }
}
.dropdown-container {
  @extend .flex;
  @extend .flex-col;
  @extend .radius-12;
  @extend .border-grey-700;
  @extend .bg-grey-800;
  @extend .shadow-page;
  overflow: hidden;
}
