.media-modal-grid {
  @extend .gap-4;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.media-modal-preview {
  @extend .section-container;
  height: 100%;
  width: 100%;

  img {
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
  }
}

.media-card {
  @extend .radius-16;
  @extend .border-grey-700;
  position: relative;
  height: 200px;
  width: 200px;

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    @extend .radius-16;
  }

  &:hover .media-card-overlay {
    visibility: visible;
    opacity: 1;
  }
}

.media-card-overlay {
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: ease-in-out 0.3s;

  .media-card-overlay-backdrop {
    @extend .bg-grey-700;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    @extend .radius-16;
  }

  p {
    word-break: break-word;
  }
}
