.bar {
  height: 6px;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow:
    0 1px 0 rgba(255, 255, 255, 0.03),
    inset 0 1px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow:
    0 1px 0 rgba(255, 255, 255, 0.03),
    inset 0 1px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow:
    0 1px 0 rgba(255, 255, 255, 0.03),
    inset 0 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow:
    0 1px 0 rgba(255, 255, 255, 0.03),
    inset 0 1px 0 rgba(0, 0, 0, 0.1);

  &.hidden {
    visibility: hidden;
  }
}

/*
This is the actual bar with stripes
*/
.bar span {
  display: inline-block;
  height: 100%;
  width: 100%;
  border-bottom-color: #ff6201;
  background-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  background-image: -webkit-linear-gradient(
    -45deg,
    var(--blue) 25%,
    transparent 25%,
    transparent 50%,
    var(--blue) 50%,
    var(--blue) 75%,
    transparent 75%,
    transparent
  );
  background-image: -moz-linear-gradient(
    -45deg,
    var(--blue) 25%,
    transparent 25%,
    transparent 50%,
    var(--blue) 50%,
    var(--blue) 75%,
    transparent 75%,
    transparent
  );
  background-image: -ms-linear-gradient(
    -45deg,
    var(--blue) 25%,
    transparent 25%,
    transparent 50%,
    var(--blue) 50%,
    var(--blue) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    var(--blue) 25%,
    transparent 25%,
    transparent 50%,
    var(--blue) 50%,
    var(--blue) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 50px 50px;
  -moz-background-size: 50px 50px;
  -ms-background-size: 50px 50px;
  background-size: 50px 50px;
  -webkit-animation: move 1s linear infinite;
  -moz-animation: move 1s linear infinite;
  -ms-animation: move 1s linear infinite;
  animation: move 1s linear infinite;
  overflow: hidden;
}

/*
Animate the stripes
*/
@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
@-ms-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
